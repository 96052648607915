* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  color: white;
}
body {
  overflow:hidden; 
  background:hsla(0,0%,0%,1);
}
#jumbotron {
  background-image: url(../public/img/CharlesBeingExcellent.jpg);
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.middle-container {
  padding: 20px;
  background-color: rgba(0,0,0,0.2);
}
.item-title, .item-text {
  display: inline-block;
}
.item-title {
  width: 100px;
}
li {
  list-style-type: none;
}
a {
  color: white;
}
@media only screen and (max-width: 800px) {
  .middle-container {
      background-color: rgba(0,0,0,0.7);
  }
  #jumbotron {
      background-image: url(../public/img/CharlesWorkingHard.jpg);
      justify-content: center;
      align-items: center;
  }
}